<template>
<div class="edit-exchange">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="edit-exchange-cont">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10px" class="demo-ruleForm">
      <el-form-item label="">
        <p>兑换标题：</p>
        <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="name">
        <p>兑换描述：</p>
        <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="score">
        <p>兑换所需积分数量：</p>
        <el-input v-model="ruleForm.score" type="number" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="score">
        <p>选择类型：</p>
        <Select v-model="ruleForm.type" style="width:200px">
          <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </el-form-item>
      <el-form-item label="" prop="score">
        <p>是否需要上传文件：</p>
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="0">是</el-radio>
          <el-radio label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="score">
        <p>如何使用说明：</p>
        <Richtxt :height="450" :dataObj="{val:ruleForm.desc}" @saveData="saveCurData" style="width: 730px"></Richtxt>
      </el-form-item>
      <el-form-item label="" prop="status">
        <p>是否上架？（若选择是则用户可查看并执行任务，选择否则只保存信息用户无法看到）：</p>
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="0">是</el-radio>
          <el-radio label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="padding-top: 20px">
      <Button type="primary" class="mr20" @click="submitForm('ruleForm')">确定</Button>
      <Button @click="cancel">取消</Button>
    </div>


  </div>
</div>
</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "editExchange",
  data(){
    return{
      ruleForm: {
        name: '',
        score:'',
        cycle_type:'',
        times_limit:'',
        status:'',
        type:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入任务标题', trigger: 'blur' },
        ],
        score:[
          { required: true, message: '请输入积分获得数量', trigger: 'blur' },
        ],
        cycle_type:[
          { required: true, message: '请选择任务类型', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请选择是否上架', trigger: 'change' },
        ],
        type:[
          { required: true, message: '请选择类型', trigger: 'change' },
        ]
      },
      typeList:[

      ]
    }
  },
  components:{
    Richtxt
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.edit-exchange{
  margin: 20px;
  font-size: 14px;
  .edit-exchange-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
}
</style>
